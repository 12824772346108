<template>
	<div class="app-body has-bottom">
		<div class="home-container scroll-cont">
			<div class="home-amount-cont" >
				<div class="back-nav">
					<div class=" f16" @click="changeHeaderNav(2)" :class="headerActive == 2 ? 'active' : ''">未检测<span class="border"></span></div>
					<div class="f16" @click="changeHeaderNav(1)" :class="headerActive == 1 ? 'active' : ''">已检测<span class="border"></span></div>
				</div>
				<div class="back-cont">
					<van-pull-refresh v-model="isLoading" @refresh="onRefresh">
						<van-list v-model="loading" :finished="finished"  @load="onLoard">
							<div class="back-list f14" v-for="item in list" :key="item.id" @click="godetail({path:'/DeviceTest',query:{imei:item.imei}})">
								<div class="back-list-top" >
									<p>设备编号：{{item.imei}} </p>
									<p class="text-grey mts">设备名称：{{item.cabinet_type}}</p>
									<p class="text-grey mts">机柜型号：{{item.device_model}}</p>
									<p class="text-grey mts">地区：{{item.country_name}}</p>
									<p class="text-grey mts">不良描述：{{item.poor_description}}</p>
									<p class="text-grey mts">报修时间：{{item.repair_time}}</p>
								</div>
							</div>
						</van-list>
					</van-pull-refresh>
					<div class="nodata" v-if="list.count == 0">
						<p><img src="../../assets/images/nodata.png" alt="" class="noImg"></p>
						<p class="f14 text-grey">暂无数据</p>
					</div>
				</div>
			</div>
		</div>
		<div class="absolute-bottom">
			<button class="btn-blue btn-lg btn-block f16" @click="godetail({path:'/DeviceTest'})">检测设备</button>
		</div>
	</div>
</template>
<script>
import wx from 'weixin-js-sdk'
import { wxbase } from "@/api/user.js"
import { Toast,PullRefresh,List,Popup  } from 'vant'
import { cabinetTestingList  } from '@/api/device'
export default{
	components: {
		[PullRefresh.name]:PullRefresh,
		[List.name]:List,
		[Popup.name]:Popup,
	},
	data() {
		return {
			headerActive:1,
			list: [],
			isLoading: false,   
			loading: false,   
			finished: false,
			currentPage : 0,
			searchInfo:{
				'status':'',
				'imei':''
			}
		}
	},
	watch: {

	},
	computed: {

    },
	mounted() {
	
	},
	methods: {
		changeHeaderNav(status) {
			this.headerActive = status
			this.onRefresh()
		},
        scanSearch() {
			var self = this
			let url = window.location.href.split('#')[0]
			wxbase(url).then(response => {
				var jsondata = response.data
				wx.config({
					debug: false,
					appId: jsondata.appid,
					timestamp: jsondata.timestamp,
					nonceStr: jsondata.nonceStr,
					signature: jsondata.signature,
					jsApiList: [
						'checkJsApi',
						'scanQRCode'
					]
					// 需要使用的JS接口列表
				});
				wx.ready(function () {
					wx.checkJsApi({
						jsApiList: ['scanQRCode'],
						success: function () {
						}
					});
					wx.scanQRCode({
						needResult: 1, // 默认为0，扫描结果由微信处理，1则直接返回扫描结果，
						scanType: ["qrCode"], // 可以指定扫二维码还是一维码，默认二者都有
						success: function (res) {
							var result = res.resultStr; // 当needResult 为 1 时，扫码返回的结果
							if(result.split('=')[1]){
								self.searchInfo.imei = result.split('=')[1]
							}else{
								Toast('当前不是合格的机柜二维码')
							}

							console.log("扫描结果："+result);
						}
					});
				}); 
				wx.error(function (res) {
					console.log("出错了：" + res.errMsg);
				});
			}).catch(error=>{
				console.log(error);
			});
        },
		isExist(arr,param) {
			if(arr.indexOf(String(param)) > -1) return true
			else return false
		},
		godetail(path) {
			this.$router.push(path)
		},
		onLoad() {
			let params = {}
			params.page = this.currentPage
			params.state = this.headerActive
			this.loading = true
			//分页数据
			cabinetTestingList(params).then(res => {
				if(res.data.error_code != 1000)
				{
						this.isLoading = false
						this.finished = true
						this.loading = false
						this.list.length = 0
						this.list.count = 0
				}else{
					if(res.data.data.total > 0) {
						
						if(this.loading)
						{
							this.list = this.list.concat(res.data.data.data)
							this.loading = false
						}
						if(this.isLoading)
						{
							this.isLoading = false
							this.list = res.data.data.data
						}
						if(this.list.length >= res.data.data.total){ 
							this.finished = true
						}else{
							this.finished = false
						}
					}else{
						this.isLoading = false
						this.finished = true
						this.loading = false
						this.list.length = 0
						this.list.count = 0
					}
				}
			}).catch(error=>{
				console.log(error)
			})
		},
		onRefresh() { 
			//下拉刷新
			this.isLoading = true
			this.currentPage = 1
			this.onLoad()
		},
		onLoard() {
			//上拉加载
			this.currentPage++
			this.loading = true
			this.onLoad()
		}
	}
}
</script>
<style lang="scss" scoped>
@import '../../assets/css/mixin';
.back-nav{
	display:flex;
	margin:0.4rem 0 0rem;
	div{
		flex:1;
		text-align:center;
		&.active{
			color:#35b0fa;
			span{
				border-bottom:3px solid #35b0fa;
				padding:0.2rem 0;
				color:#35b0fa;
				font-weight:500;
				display:block;
				width:20%;
				margin:0 auto;
			}
		}
	}
}
.back-search{
	margin:0.4rem 0.4rem 0;
	background:#fff;
	border-radius:5px;
	display:flex;
	padding:0.3rem 0;
	align-items:center;
	box-shadow:0px 1px 1px #eeeeee;
	.select-box{
		padding:0 0.4rem;
		flex:1;
		select{
			-webkit-appearance:none;
			apperance:none;
			background:url('../../assets/images/arrow-down.png') no-repeat right center;
			background-size:0.25rem;
			padding-right:0.4rem;
			width:100%;
		}
	}
	.input-box{
		flex:1;
		background:none;
		padding:0 0.2rem;
		position:relative;
		input{
			width:100%;
			padding-left:1.0rem;
		}
		.search-scan{
			position:absolute;
			left:0.4rem;
			top:-0.1rem;
		}
		.search-icon{
			position:absolute;
			right:0.4rem;
			top:-0.1rem;
		}
	}
}
.back-title{
	margin:0.4rem 0.4rem 0;
}
.back-list{
	margin:0.4rem;
	background:#fff;
	border-radius:5px;
	padding:0 0.4rem;
	box-shadow:0px 1px 1px #eeeeee;
	.back-list-top{
		padding:0.4rem 0;
	}
	.back-list-bottom{
		padding:0.4rem 0;
		border-top:1px solid #f5f5f5;
	}
	table{
		width:100%;
		text-align:center;
		padding:0;
		margin:0.4rem 0;
		border-collapse: collapse;
		border-spacing:0;
		tr > th{
			color:#999999;
		}
		tr > td , tr > th{
			border:1px solid #dbdbdb;
			padding:10px 0;
		}
	}
}
</style>